import React, { useEffect, useRef, useState } from 'react'
import { LogoutModal } from '../../component/LogoutModal';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from "react-router-dom"
import { FormattedMessage } from 'react-intl';
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import HamburgerIcon from '../../assets/hamburger.png'
import DashboardIcon from '../../assets/dashboard.png'
import CreditIcon from '../../assets/credit-approval.png'
import PendingIcon from '../../assets/pending.png'
import ApproveIcon from '../../assets/approved.png'
import RejectedIcon from '../../assets/rejected.png'
import ReportIcon from '../../assets/report.png'
import SettingIcon from '../../assets/setting.png'
import LogoutIcon from '../../assets/logout.png'
import '../../css/sidebar.css'
// import { logout } from "../../redux/slices/userSlice"
// import { useDispatch } from "react-redux";


export const Sidebar = () => {

    const iconStyle = {
        width: '30px',
        height: '30px',
    }

    const navigate = useNavigate()

    // const dispatch = useDispatch()

    const isMobile = useMediaQuery("(max-width: 600px)")

    const [collapsed, setCollapsed] = React.useState(false)
    const [toggled, setToggled] = React.useState(false)

    // for logout
    const [openModal, setOpenModal] = useState(false)
    const { logout } = useLogout()

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleLogout = async () => {
        // await dispatch(logout())
        // localStorage.clear()
        navigate("/")
        logout()
    }

    const handleNoLogout = () => {
        setOpenModal(false)
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', paddingTop: '50px' }}>

            <ProSidebar
                collapsed={collapsed}
                onBackdropClick={() => setToggled(false)}
                onToggle={() => setCollapsed(!collapsed)}
                toggled={toggled}
                breakPoint="600px"
                width="275px"
            >
                <Box className='hamburger'>
                    <IconButton
                        onClick={() => {
                            if (isMobile) {
                                setToggled(false);
                            } else {
                                setCollapsed(!collapsed)
                            }
                        }}
                    >
                        <img src={HamburgerIcon} alt="report" width="30" height="30" />
                    </IconButton>

                </Box>

                <Menu>
                    <MenuItem
                        icon={<img src={DashboardIcon} alt="dashboard" style={iconStyle} />}
                        onClick={() => navigate('/')}
                    >
                        <FormattedMessage id='dashboardNav' />
                    </MenuItem>
                    <SubMenu icon={<img src={CreditIcon} alt="credit" style={iconStyle} />} label={<FormattedMessage id='credit' />}>
                        <MenuItem
                            icon={<img src={PendingIcon} alt="pending" style={iconStyle} />}
                            onClick={() => navigate('/pending-request')}
                        >
                            <FormattedMessage id='pendingNav' />
                        </MenuItem>
                        <MenuItem
                            icon={<img src={ApproveIcon} alt="approved" style={iconStyle} />}
                            onClick={() => navigate('/approved')}
                        >
                            <FormattedMessage id='approvedNav' />
                        </MenuItem>
                        <MenuItem
                            icon={<img src={RejectedIcon} alt="rejected" style={iconStyle} />}
                            onClick={() => navigate('/rejected')}
                        >
                            <FormattedMessage id='rejectedNav' />
                        </MenuItem>
                    </SubMenu>
                    <MenuItem
                        icon={<img src={ReportIcon} alt="report" style={iconStyle} />}
                        onClick={() => navigate('/report')}
                    >
                        <FormattedMessage id='reportNav' />
                    </MenuItem>
                    <MenuItem
                        icon={<img src={SettingIcon} alt="setting" style={iconStyle} />}
                        onClick={() => navigate('/setting')}
                    >
                        <FormattedMessage id='settingNav' />
                    </MenuItem>
                    <MenuItem
                        icon={<img src={LogoutIcon} alt="dashboard" style={iconStyle} />}
                        onClick={handleOpenModal}
                    >
                        <FormattedMessage id='logoutNav' />
                    </MenuItem>
                </Menu>
            </ProSidebar>

            {isMobile && (
                <Box className='hamburger'>
                    <img src={HamburgerIcon} alt="report" width="30" height="30" onClick={() => setToggled(!toggled)} />
                </Box>
            )}

            {openModal && (
                <LogoutModal
                    message="logout.confirmation.message"
                    onOK={handleLogout}
                    onCancel={handleNoLogout}
                    onClose={handleNoLogout}
                />
            )}
        </Box>

    )
}