import React from 'react';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';
import OKIcon from '../assets/approve.png'

const useStyles = makeStyles({
    button: {
        alignItems: 'center',
        color: '#2A2A2A !important',
        borderRadius: '8px',
        fontSize: '12.5px',
        width: '8rem',
        height: '2rem',
        backgroundColor: '#F5BB8D !important',
        '&:hover': {
            backgroundColor: '#F5BB8DBF',
        },
    },
    
    icon: {
        marginRight: '8px', 
        width: '20px',
        height: '20px', 
    },
})

const OrangeButton = ({ id, backgroundColor, hasIcon, ...otherProps }) => {
    const classes = useStyles();

    return (
        <Button variant="contained" className={classes.button} {...otherProps}>
            {hasIcon && <img src={OKIcon} alt="OK Icon" className={classes.icon} />}
            <FormattedMessage id={id} />
        </Button>
    )
}

export default OrangeButton;
