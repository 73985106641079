// DateTimeComponent.jsx
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

const DateTimeComponent = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [])

  const formatDateTime = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = `0${dateTime.getMonth() + 1}`.slice(-2);
    const day = `0${dateTime.getDate()}`.slice(-2);
    const hours = `0${dateTime.getHours()}`.slice(-2);
    const minutes = `0${dateTime.getMinutes()}`.slice(-2);
    const seconds = `0${dateTime.getSeconds()}`.slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <Typography style={{ color: '#707070', fontSize: '14px' }}>
      {formatDateTime(dateTime)}
    </Typography>
  )
}

export default DateTimeComponent;
