import React from 'react';
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const CustomDataGrid = ({ columns, rows, getRowId }) => {
  

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            getRowId={getRowId}
            disableColumnMenu
            disableColumnSort
            disableRowSelectionOnClick
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10]}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
            sx={{
                border: '1px solid #F5A870',             
                ['& .MuiDataGrid-row:hover']: {
                    backgroundColor: '#f7f3e9',
                },
                [`& .${gridClasses.row}.even`]: {
                    backgroundColor: '#E7E2DE'
                },
                [`& .${gridClasses.row}.odd`]: {
                    backgroundColor: '#FFFFFF'
                },
                ['& .MuiDataGrid-footerContainer']: {
                    backgroundColor: '#FFFFFF'
                },
                ['& .MuiDataGrid-columnHeader']: {
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1px solid #F5A870',
                },
            }}
        />
    )
}


export default CustomDataGrid;
