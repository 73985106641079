import React, { useEffect } from 'react'
import RequestData from '../DataComponent/RequestData';
import { FormattedMessage } from 'react-intl'
import { Box, useMediaQuery } from '@mui/material'
import Header from '../../../component/Header'
import '../../../css/credit-request.css'

export const PendingRequest = ({ boxSize, defaultBox }) => {
    const isLargeScreen = useMediaQuery("(min-width: 1200px)")

    return (
        <Box sx={boxSize}>
            <Header title={<FormattedMessage id='pendingNav' />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" }
                }}
            >
                {/* tab header */}
                <Box sx={defaultBox}>
                    <RequestData />
                </Box>
            </Box>
        </Box>
    )
}
