import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { IconButton, Box, Button, Divider, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import '../css/global.css'
import OrangeButton from './OrangeButton';
import RedButton from './RedButton';


const DeleteModal = ({ message, onDelete, onCancel, onClose }) => {

    const [openModal, setOpenModal] = useState(true);

    const handleCloseModal = () => {
        setOpenModal(false);
        onClose();
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal} // Always set open to true in the ConfirmationModal component
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box className="logout-confirmation">
                        <Box textAlign='end' padding='5px'>
                            <IconButton onClick={onClose}>
                                <CloseIcon style={{ color: '#F5A870' }} />
                            </IconButton>
                        </Box>

                        <Box flexGrow={1} textAlign="center" paddingBottom="15px" >
                            <FormattedMessage id={message} />
                        </Box>


                        <Divider variant='middle' className='divider' />

                        <Box className="orange-red-button-padd">
                            <OrangeButton id='logout.yes' onClick={onDelete} hasIcon='true'/>

                            <RedButton id='logout.cancel' onClick={onCancel} hasIcon='true' style={{ marginLeft: '10px' }} />
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default DeleteModal