import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom'
import { Sidebar } from './Sidebar';
import { Navbar } from './Navbar';
import { IntlProvider } from "react-intl";
import { LOCALES } from "../../i18n/locales"
import { messages } from "../../i18n/messages"
import { Box, useMediaQuery } from '@mui/material';
// import { useSelector } from "react-redux";

export const Layout = () => {
    // const user = useSelector((state) => state?.user?.token)
    const { user } = useAuthContext()

    const [currentLocale, setCurrentLocale] = useState(getInitialLocal())

    const handleChange = (locale) => {
        setCurrentLocale(locale)
        localStorage.setItem("locale", locale)
    }

    function getInitialLocal() {
        const savedLocale = localStorage.getItem('locale')
        return savedLocale || LOCALES.ENGLISH
    }

    const [collapsed, setCollapsed] = React.useState(false);

    const isNonMobile = useMediaQuery('(min-width: 1024px)');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const sidebarProps = {
        isSidebarOpen,
        setIsSidebarOpen,
    };

    return (
        <IntlProvider
            messages={messages[currentLocale]}
            locale={currentLocale}
            defaultLocale={LOCALES.ENGLISH}
        >
            <Box display='flex' width="100%" height="100%">
                {user && (
                    <Sidebar {...sidebarProps} />
                )}

                {user && (
                    <Navbar
                        style={{ position: 'fixed' }}
                        isSidebarOpen={isSidebarOpen}
                        currentLocale={currentLocale}
                        handleChange={handleChange}
                    />
                )}

                <Box sx={{ paddingTop: '80px' }} >
                    <Outlet />
                </Box>

            </Box>
        </IntlProvider>
    )
}
