import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ApprovedData from '../DataComponent/ApprovedData';
import Header from '../../../component/Header'
import Typography from '@mui/material/Typography';
import OrangeButton from '../../../component/OrangeButton';
import { Box, Divider, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom';
import '../../../css/credit-request.css'
import '../../../css/global.css'
import { LogTable } from '../DataComponent/LogTable';
import { useAuthContext } from '../../../hooks/useAuthContext';
// import { useFetchReportQuery } from '../../../redux/slices/apiSlice';
// import { useSelector } from "react-redux";

export const IndividualLog = ({ boxSize, defaultBox }) => {
    const isLargeScreen = useMediaQuery("(min-width: 1200px)")
    const Navigate = useNavigate()

    const { user } = useAuthContext()

    const logFound = useParams()
    const [details, setDetails] = useState({})
    const [log, setLog] = useState({})

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // const { data, isLoading } = useFetchReportQuery({id: logFound.id, token})

    const fetchLog = async () => {
        try {
            const { data } = await axios.get(`/api/v1/report/${logFound.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            setDetails(data)

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchLog()
    }, [logFound.id])

    // useEffect(() => {
    //     if (!isLoading) {
    //         setDetails(data)
    //     }
    // }, [data])

    /* const fetchLog = async () => {z
        try {
            const { data } = await axios.get(`/api/v1/report/${logFound.id}`)
            setDetails(data)

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchLog()
    }, [logFound.id]) */

    const { actual_pay, credit_id } = details
    const credit_limit = credit_id?.credit_limit

    return (
        <Box sx={boxSize}>
            <Box className='header'>
                <Header title={<FormattedMessage id='log.details' />} />
                <OrangeButton id='return' onClick={() => Navigate('/report')} />
            </Box>

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" }
                }}
            >
                <Box sx={{ gridColumn: 'span 12' }}>
                    <Box className='details-box'>
                        <Box display='log-container'>
                            <Typography className='details-label'><FormattedMessage id='agentID' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{logFound.id}</Typography>
                        </Box>
                        <Box display='log-container'>
                            <Typography className='details-label'><FormattedMessage id='credit.limit' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{credit_limit}</Typography>
                        </Box>
                        <Box display='log-container'>
                            <Typography className='details-label'><FormattedMessage id='outstanding' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{actual_pay}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={defaultBox}>
                    <LogTable />
                </Box>
            </Box>
        </Box>
    )
}
