import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from "react-intl";
import { IconButton, Box, Button, Divider, Grid } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import OrangeButton from './OrangeButton';
import '../css/global.css';

export const PinModal = ({ message, pin, onOK, onClose }) => {

    const [openModal, setOpenModal] = useState(true);

    const handleCloseModal = () => {
        setOpenModal(false)
        onClose()
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box className="pin-container">
                        <Box textAlign='end' padding='5px'>
                            <IconButton onClick={onClose}>
                                <CloseIcon style={{ color: '#F5A870' }} />
                            </IconButton>
                        </Box>


                        <Box flexGrow={1} textAlign="center" paddingBottom="15px" >
                            <Typography className='details-label'><FormattedMessage id={message} />:</Typography>
                        </Box>
                        <Box flexGrow={1} textAlign="center" paddingBottom="25px" >
                            {pin}
                        </Box>


                        {/* <Divider variant='middle' className='divider' /> */}

                        <Grid container spacing={2} sx={{justifyContent: 'center'}}>
                            <Grid item>
                                <CopyToClipboard text={pin}>
                                    <Button>
                                        Copy Pin
                                    </Button>
                                </CopyToClipboard>
                            </Grid>
                            <Grid item>
                                <OrangeButton id='ok' onClick={onOK} hasIcon='true' />
                            </Grid>
                        </Grid>

                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
