import { CssBaseline, useMediaQuery } from '@mui/material';
import { Layout } from './scenes/global/Layout';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from './scenes/Dashboard';
import { PendingRequest } from './scenes/Credit/Pending/PendingRequest.jsx';
import { LogIndex } from './scenes/Credit/Approved/ApprovedIndex.jsx';
import { Report } from './scenes/Report/ReportIndex.jsx';
import { Setting } from './scenes/Authentication/Setting';
import LoginPage from './scenes/Authentication/LoginPage';
import { useAuthContext } from './hooks/useAuthContext';
import './index.css'
import { PendingDetails } from './scenes/Credit/Pending/PendingDetails.jsx';
import { IndividualLog } from './scenes/Credit/Approved/IndividualLog.jsx';
import { RejectedIndex } from './scenes/Credit/Rejected/RejectedIndex.jsx';
// import { useSelector } from "react-redux";
// import { useEffect } from 'react';

function App() {
    const isLarge = useMediaQuery("(min-width: 1024px)")
    const isBelow600 = useMediaQuery("(max-width: 600px)")

    const boxSize = {
        padding: isBelow600 ? '2rem 2rem 0 0' : '1.5rem 2.5rem'
    }

    const defaultBox = {
        gridColumn: "span 12",
        height: "max-content",
        backgroundColor: "#FFFFFF",
        borderRadius: "0.55rem",
        padding: '2rem',
    }

    // test

    // const user = useSelector((state) => state?.user?.token)
    const { user } = useAuthContext()

    function loggedIn() {
        return (
            <>
                <Route index element={<Dashboard boxSize={boxSize} defaultBox={defaultBox} />} />

                <Route path="pending-request" element={<PendingRequest boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="approved" element={<LogIndex boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="rejected" element={<RejectedIndex boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="report" element={<Report boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="setting" element={<Setting boxSize={boxSize} defaultBox={defaultBox} />} />

                <Route path="pending-details/:id" element={<PendingDetails boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="log/:id" element={<IndividualLog boxSize={boxSize} defaultBox={defaultBox} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        )
    }

    function loggedOut() {
        return (
            <>
                <Route index element={<LoginPage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        )
    }

    return (
        <div className='app'>
            <BrowserRouter>
                <CssBaseline />
                <Routes>
                    <Route path="/" element={<Layout />}>
                        {user ? loggedIn() : loggedOut()}
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
