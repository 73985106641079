import * as yup from "yup";
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from "formik";
import { useAuthContext } from '../../hooks/useAuthContext'
import { Box, FormLabel, TextField, useMediaQuery } from '@mui/material'
import { LogoutModal } from '../../component/LogoutModal';
import Header from '../../component/Header'
import OrangeButton from '../../component/OrangeButton';
import '../../css/setting.css'
import '../../css/global.css'

export const Setting = ({ boxSize, defaultBox }) => {

    const isLargeScreen = useMediaQuery("(min-width: 1200px)")
    const isLaptop = useMediaQuery("(min-width: 768px)")

    const { user } = useAuthContext()
    const Navigate = useNavigate()

    // const [openModal, setOpenModal] = useState(false)
    // const handleOpenModal = () => {
    //     setOpenModal(true)
    // }

    // const handleClick = () => {
    //     handleSave()
    //     Navigate('/setting')
    // }

    // const handleClickCancel = () => {
    //     setOpenModal(false)        
    // }

    const handleSave = async (values) => {
        try {
            const { data } = await axios.patch(`/api/user/update`, values, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={boxSize}>
            <Header title={<FormattedMessage id='settingNav' />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" }
                }}
            >
                <Box sx={defaultBox}>
                    <Formik
                        // onClick={handleOpenModal}
                        initialValues={initialValuesToChange}
                        validationSchema={passwordSchema}
                    >
                        {({ errors, touched }) => (
                            <Form className="form">
                                <Box className="field">
                                    <FormLabel className="label"><FormattedMessage id="current.password" /></FormLabel>
                                    <Field
                                        name="currentPassword"
                                        as={TextField}
                                        sx={{ width: isLargeScreen ? '30%' : isLaptop ? '40%' : 'auto', color: '#393636' }}
                                        InputProps={{ style: { height: "2.5rem" } }}
                                    />
                                    {errors.currentPassword && touched.currentPassword ? (
                                        <div className="error">{errors.currentPassword}</div>
                                    ) : null}
                                </Box>

                                <Box className="field">
                                    <FormLabel className="label"><FormattedMessage id="new.password" /></FormLabel>
                                    <Field
                                        name="newPassword"
                                        as={TextField}
                                        sx={{ width: isLargeScreen ? '30%' : isLaptop ? '40%' : 'auto' }}
                                        InputProps={{ style: { height: "2.5rem" } }}
                                    />
                                    {errors.newPassword && touched.newPassword ? (
                                        <div className="error">{errors.newPassword}</div>
                                    ) : null}
                                </Box>

                                <Box className="field">
                                    <FormLabel className="label"><FormattedMessage id="confirm.password" /></FormLabel>
                                    <Field
                                        name="confirmPassword"
                                        as={TextField}
                                        sx={{ width: isLargeScreen ? '30%' : isLaptop ? '40%' : 'auto' }}
                                        InputProps={{ style: { height: "2.5rem" } }}
                                    />
                                    {errors.confirmPassword && touched.confirmPassword ? (
                                        <div className="error">{errors.confirmPassword}</div>
                                    ) : null}
                                </Box>

                                <OrangeButton type="submit" id="save" />

                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>

            {/* {openModal && (
                <LogoutModal
                    message="sure.change.message"
                    onOK={handleClick}
                    onCancel={handleClickCancel}
                    onClose={handleClickCancel}
                />
            )} */}
        </Box>
    )
}

const initialValuesToChange = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
}

const passwordSchema = yup.object().shape({
    currentPassword: yup.string().required(<FormattedMessage id="current.empty" />),
    newPassword: yup.string().required(<FormattedMessage id="new.empty" />),
    confirmPassword: yup.string().required(<FormattedMessage id="confirm.empty" />).oneOf([yup.ref('newPassword'), null], <FormattedMessage id="confirm.failed" />)
})

