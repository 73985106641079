import { LOCALES } from "./locales";

export const messages = {
      [LOCALES.ENGLISH]: {
            // general
            "ok": "OK",
            "agentID": "Agent ID",
            "action": "Action",
            "save": "Save",
            "return": "Return",
            "view": "View",
            "amount": "Amount",
            "process": "Process",
            "request": "Request",
            "approve": "Approve",
            "approved": "Approved",
            "reject": "Reject",
            "delete": "Delete",
            "approvedID": "Approved ID",
            "createdAt": "Created At",
            "approvedAt": "Approved At",
            "rejectedAt": "Rejected At",
            "credit.pin": "Credit Pin",

            "delete": "Delete",
            "delete.confirmation.message": "Are you sure you want to delete?",
            "delete.yes": "Yes",
            "delete.cancel": "No",

            // sidebar
            "dashboardNav": "Dashboard",
            "credit": "Credit",
            "pendingNav": "Pending Request",
            "approvedNav": "Approved",
            "rejectedNav": "Rejected",
            "reportNav": "Report",
            "settingNav": "Setting",
            "logoutNav": "Logout",

            // login page
            "login.username": "Username",
            "login.password": "Password",
            "login": "Login",
            "login.captcha": "Captcha",
            "login.username.error": "Please enter the username",
            "login.password.error": "Please enter the password",
            "login.captcha.error": "Please enter the captcha",
            "login.failed.title": "Error",
            "login.failed.message": "These credentials does not match with our records",
            "logout": "Logout",
            "logout.confirmation.message": "Are you sure you want to Logout?",
            "logout.yes": "Yes",
            "logout.cancel": "Cancel",

            // dashboard
            "pending.request": "Pending Request",
            "outstanding.amount": "Outstanding Amount",
            "top": "Top 10",

            // page: request details
            "request.details": "Request Details",
            "sure.approve.message": "Are you sure you want to approve this request?",
            "sure.reject.message": "Are you sure you want to reject this request?",

            // page: approved details
            "approved.details": "Approved Details",
            "pin.message": "PIN Number",

            // page: log
            "log_type": "Log Type",
            "description": "Description",

            // dataset
            "credit.request": "Credit Request",
            "status": "Status",
            "log.details": "Log Details",
            "credit.limit": "Credit Limit",
            "last.transaction": "Last Transaction Date",
            "outstanding": "Outstanding Balance",
            "history": "History",

            // page: change password
            "change.password": "Change Password",
            "current.password": "Current Password",
            "new.password": "New Password",
            "confirm.password": "Confirm Password",
            "success.message": "Your password has been changed successfully.",
            "go.to.home": "Go to home",
            "current.empty": "Please enter your current password.",
            "new.empty": "Please enter your new password",
            "confirm.empty": "Please confirm your new password.",
            "confirm.failed": "Your password does not match.",
            "sure.change.message": "Are you sure you want to change your password?",
      },

      [LOCALES.CHINESE]: {
            // general
            "ok": "OK",
            "agentID": "代理 ID",
            "action": "行动",
            "save": "保存",
            "return": "返回",
            "view": "返回",
            "amount": "数目",
            "process": "采取行动",
            "request": "申请列表",
            "approve": "Approve",
            "approved": "批准列表",
            "reject": "拒绝",
            "delete": "删除",
            "approvedID": "批准 ID",
            "createdAt": "创建日期",
            "approvedAt": "批准日期",
            "rejectedAt": "被拒日期",
            "credit.pin": "Pin 码",

            "delete": "删除",
            "delete.confirmation.message": "您确定要删除吗？",
            "delete.yes": "是",
            "delete.cancel": "否",

            // sidebar
            "dashboardNav": "仪表盘",
            "credit": "Credit",
            "pendingNav": "待处理请求",
            "approvedNav": "已通过请求",
            "rejectedNav": "被拒绝请求",
            "reportNav": "报告",
            "settingNav": "设置",
            "logoutNav": "登出",

            // login page
            "login.username": "登录名",
            "login.password": "密码",
            "login": "登录",
            "login.captcha": "验证码",
            "login.username.error": "请输入用户登录名",
            "login.password.error": "请输入登录密码",
            "login.captcha.error": "请输入验证码",
            "login.failed.title": "登陆失败",
            "login.failed.message": "您的用户名/密码不匹配。",
            "logout": "退出",
            "logout.confirmation.message": "确定要登出吗?",
            "logout.yes": "是",
            "logout.cancel": "否",

            // dashboard
            "pending.request": "待处理请求",
            "outstanding.amount": "未付款余额",
            "top": "前十名",

            // page: request details
            "request.details": "请求详情",
            "sure.approve.message": "确定要批准此请求吗？",
            "sure.reject.message": "确定要拒绝此请求吗？",

            // page: approved details
            "approved.details": "批准详情",
            "pin.message": "PIN 码",

            // page: log
            "log_type": "Log Type",
            "description": "Description",

            // dataset
            "credit.request": "申请数目",
            "status": "状态",
            "log.details": "更动事项",
            "credit.limit": "信用额度",
            "last.transaction": "最后交易日期",
            "outstanding": "余额",
            "history": "过往记录",

            // page: change password
            "change.password": "更换密码",
            "current.password": "当前密码",
            "new.password": "新密码",
            "confirm.password": "确定新密码",
            "success.message": "密码已成功被更换。",
            "go.to.home": "首页",
            "current.empty": "请输入您现在的密码.",
            "new.empty": "请输入您的新密码",
            "confirm.empty": "请确认您的新密码",
            "confirm.failed": "您输入的密码不正确",
      }
}