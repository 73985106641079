import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import Header from '../../../component/Header.jsx'
import ApprovedData from '../DataComponent/ApprovedData.jsx'

export const LogIndex = ({ boxSize, defaultBox }) => {

    const isLargeScreen = useMediaQuery("(min-width: 1200px)")

    return (
        <Box sx={boxSize}>
            <Header title={<FormattedMessage id='approvedNav' />} />

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" }
                }}
            >
                <Box sx={defaultBox}>
                    <ApprovedData />
                </Box>
            </Box>
        </Box>
    )
}
