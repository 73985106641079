import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Box, Button, Modal, TableContainer, TableHead, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CustomDataGrid from '../../../component/DataGrid';
import moment from 'moment';
import { useAuthContext } from '../../../hooks/useAuthContext';
// import { useFetchLogQuery } from '../../../redux/slices/apiSlice';
// import { useSelector } from "react-redux";

export const LogTable = () => {
    const dataGridWidth = useMediaQuery("(max-width: 1200px)")

    const [openLogDialog, setOpenLogDialog] = useState(false)
    const logFound = useParams()
    const [log, setLog] = useState({})

    const { user } = useAuthContext()

    const logColumn = [
        { field: 'rowIndex', headerName: 'No', width: 90, headerAlign: "center", align: "center" },
        {
            field: 'log_type',
            headerName: <FormattedMessage id='log_type' />,
            minWidth: dataGridWidth ? 180 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'description',
            headerName: <FormattedMessage id='description' />,
            type: 'number',
            minWidth: dataGridWidth ? 180 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'amount',
            headerName: <FormattedMessage id='amount' />,
            type: 'number',
            minWidth: dataGridWidth ? 180 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: <FormattedMessage id="createdAt" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mma"),
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
    ]

    const [pageState, setPageState] = useState({
        isLoading: false,
        logs: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchLogDetails = async () => {
        try {
            const { data } = await axios.get(`/api/v1/log/${logFound.id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                }
            )

            const listOfLogs = data.foundLogs.map((logs, index) => ({
                ...logs,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, logs: listOfLogs, total: data.pageTotal }))

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchLogDetails()
    }, [logFound.id])

    const [fetchData, setFetchData] = useState([])

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // let data = null;
    // let logList = null;
    // const { data: fetch, isLoading } = useFetchLogQuery({ id: logFound.id, token })

    // if (!isLoading) {
    //     data = fetch
    //     logList = data.foundLogs.map((logs, index) => ({
    //         ...logs,
    //         rowIndex: index + 1
    //     }))
    // }

    // useEffect(() => {
    //     if (!isLoading) {
    //         setFetchData(logList)
    //     }
    // }, [data])

    return (
        <CustomDataGrid
            columns={logColumn}
            rows={pageState.logs}
            getRowId={(row) => row._id}
        />
    )
}
