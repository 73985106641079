import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { AuthContextProvider } from './context/authContext';

import { configureStore } from '@reduxjs/toolkit'
import globalReducer from "./state"

// import { store } from './redux/store';

const store = configureStore({
  reducer: {
    global: globalReducer,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <AuthContextProvider>
        <App />
        </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);

