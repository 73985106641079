import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom';
import { LogoutModal } from '../../../component/LogoutModal';
import { Box, Divider, Grid, useMediaQuery, Button } from '@mui/material'
import { PinModal } from '../../../component/PinModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Header from '../../../component/Header'
import Typography from '@mui/material/Typography';
import OrangeButton from '../../../component/OrangeButton';
import RedButton from '../../../component/RedButton';
import '../../../css/credit-request.css'
import '../../../css/global.css'
import { useAuthContext } from '../../../hooks/useAuthContext';
// import { useFetchRequestQuery, useHandleRequestMutation } from "../../../redux/slices/apiSlice";
// import { useSelector } from "react-redux";

export const PendingDetails = ({ boxSize, defaultBox }) => {
    const isBelow375 = useMediaQuery("(max-width: 375px)")
    const isLargeScreen = useMediaQuery("(min-width: 1200px)")
    const Navigate = useNavigate()
    const { user } = useAuthContext()


    const requestFound = useParams()
    const [details, setDetails] = useState({})

    function formatDateString(inputDateString) {
        const date = new Date(inputDateString)
        const year = date.getUTCFullYear()
        const month = String(date.getUTCMonth() + 1).padStart(2, '0')
        const day = String(date.getUTCDate()).padStart(2, '0')
        const hours = String(date.getUTCHours()).padStart(2, '0')
        const minutes = String(date.getUTCMinutes()).padStart(2, '0')
        const seconds = String(date.getUTCSeconds()).padStart(2, '0')

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }

    const formattedDate = formatDateString(details.createdAt)

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // const { data, isLoading } = useFetchRequestQuery({id: requestFound.id, token})

    // useEffect(()=>{
    //     if (!isLoading) {
    //         setDetails(data)
    //     }
    // }, [data])


    useEffect(() => {
        const process = async () => {
            try {
                const { data } = await axios.get(`/api/v1/credit/${requestFound.id}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                })
                setDetails(data)

            } catch (error) {
                console.log(error)
            }
        }

        process()
    }, [requestFound.id])

    const handleApprove = async () => {
        try {
            const response = await axios.patch('/api/v1/credit/patch', {
                credit_id: requestFound.id,
                type: 'approve',
            })

            setDetails(response.data)
            console.log(response)

            // open modal tell the pin
            // handleOpenPinModal(response.data.pin)
            // navigate to approved page

        } catch (error) {
            console.error('Error approving credit:', error)
        }
    }

    const handleReject = async () => {
        try {
            const response = await axios.patch('/api/v1/credit/patch', {
                credit_id: requestFound.id,
                type: 'reject'
            })

            // const patch = { credit_id: requestFound.id, type: "reject" }

            // const { data } = useHandleRequestMutation(patch)

            setDetails(response.data);
        } catch (error) {
            console.error('Error rejecting credit:', error)
        }
    }

    const [openApproveModal, setOpenApproveModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)

    const handleOpenApproveModal = () => {
        setOpenApproveModal(true)
    }

    const handleClickApprove = () => {
        handleApprove()
        Navigate('/approved')
    }

    //const handleClickApprove = () => {
    // const useHandleClickApprove = () => {
    // handleApprove()
    // const patch = { credit_id: requestFound.id, type: "approve" }
    // const { data } = useHandleRequestMutation(patch)
    // setDetails(data)
    // handleOpenPinModal(data.pin)
    // Navigate('/approved')
    // }

    const handleClickCancel = () => {
        setOpenApproveModal(false)
        setOpenRejectModal(false)
        setOpenPinModal(false)
    }

    const handleOpenRejectModal = () => {
        setOpenRejectModal(true)
    }

    //const handleClickReject = () => {
    const handleClickReject = () => {
        // const patch = { credit_id: requestFound.id, type: "reject" }
        // const { data } = useHandleRequestMutation(patch)
        // setDetails(data);
        handleReject()
        Navigate('/rejected')
    }

    const [pin, setPin] = useState("")
    const [openPinModal, setOpenPinModal] = useState(false)
    const handleOpenPinModal = (pin) => {
        setOpenPinModal(true)
        setPin(pin)
    }

    const handleClickOK = () => {
        Navigate('/approved')
    }

    return (
        <Box sx={boxSize}>
            <Box className='header'>
                <Header title={<FormattedMessage id='request.details' />} />
                <OrangeButton id='return' onClick={() => Navigate('/pending-request')} />
            </Box>

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" }
                }}
            >
                {/* tab header */}
                <Box sx={defaultBox}>
                    <Box className='details-box'>
                        <Box className='details-info'>
                            <Typography className='details-label' >Request </Typography>
                            <Typography style={{ marginLeft: '10px' }}>{details._id}</Typography>
                        </Box>
                        <Box className='details-info'>
                            <Typography className='details-label'><FormattedMessage id='createdAt' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{formattedDate}</Typography>
                        </Box>
                    </Box>

                    <Divider className='divider' />

                    <Box className='details-box' sx={{ paddingTop: '20px' }}>
                        <Box className='details-info'>
                            <Typography className='details-label'><FormattedMessage id='agentID' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{details.agent_id}</Typography>
                        </Box>
                        <Box className='details-info'>
                            <Typography className='details-label'><FormattedMessage id='credit.request' />:</Typography>
                            <Typography style={{ marginLeft: '10px' }} >{details.credit_limit}</Typography>
                        </Box>

                        <Grid container spacing={2} className='details-info'>
                            <Grid item>
                                <Typography className='details-label'>
                                    <FormattedMessage id='pin.message' />:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{ marginLeft: '10px' }}>
                                    {pin || ''}
                                </Typography>
                            </Grid>
                            <Grid item className='copy-button'>
                                {pin && (
                                    <CopyToClipboard text={pin}>
                                        <Button >
                                            Copy Pin
                                        </Button>
                                    </CopyToClipboard>
                                )}
                            </Grid>

                        </Grid>

                    </Box>


                    <Grid container spacing={2} sx={{ paddingTop: '10px', justifyContent: 'center' }}>
                        <Grid item>
                            <OrangeButton id='approve' onClick={handleOpenApproveModal} hasIcon='true' />
                        </Grid>
                        {!pin && (
                            <Grid item>
                                <RedButton
                                    id='reject'
                                    onClick={handleOpenRejectModal}
                                    hasIcon='true'
                                    style={{ marginLeft: isBelow375 ? '0' : '10px' }}
                                />
                            </Grid>
                        )}
                    </Grid >
                </Box>
            </Box>

            {openApproveModal && (
                <LogoutModal
                    message="sure.approve.message"
                    onOK={handleClickApprove}
                    onCancel={handleClickCancel}
                    onClose={handleClickCancel}
                />
            )}

            {openRejectModal && (
                <LogoutModal
                    message="sure.reject.message"
                    onOK={handleClickReject}
                    onCancel={handleClickCancel}
                    onClose={handleClickCancel}
                />
            )}

            {openPinModal && (
                <PinModal
                    message="pin.message"
                    pin={pin}
                    onOK={handleClickOK}
                    onClose={handleClickCancel}
                />
            )}
        </Box>
    )
}
