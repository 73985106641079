import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import { AppBar, Toolbar, Box, Typography, useMediaQuery, Menu, MenuItem, Button, Grid } from "@mui/material";
import FlexBetween from '../../component/FlexBetween';
import DateTimeComponent from '../../component/DateTime';
import ClockIcon from '../../assets/clock.png'
import UserIcon from '../../assets/userorange.png'
import TranslateIcon from '../../assets/translate.png'
import EnglishFlag from '../../assets/us.png'
import ChineseFlag from '../../assets/cn.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GrabLogo from '../../assets/grab_logo.png'
import '../../css/global.css'



export const Navbar = ({ currentLocale, handleChange }) => {

    const isBelow600 = useMediaQuery("(max-width: 600px)")
    const isLargeScreen = useMediaQuery("(min-width: 1024px)")

    const Dropdown = () => {
        const [anchorEl, setAnchorEl] = useState(null)
        const [isOpen, setIsOpen] = useState(false)
        const ref = useRef(null)

        const languageButtonStyle = {
            flexGrow: 1,
            backgroundColor: '#F5BB8D',
            color: '#707070',
            padding: '10px 20px'
        }

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
            setIsOpen(!isOpen)
        }

        const handleClose = () => {
            setAnchorEl(null)
            setIsOpen(false)
        }

        useEffect(() => {
            const handleOutsideClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose()
                }
            }

            document.addEventListener("mousedown", handleOutsideClick)

            return () => {
                document.removeEventListener("mousedown", handleOutsideClick)
            }
        }, [ref])

        return (
            <FlexBetween ref={ref} gap="1.5rem">

                {/* dropdown for setting and logout */}
                <FlexBetween
                    borderRadius="9px"
                    gap="1.5rem"
                    p={isLargeScreen ? "0.5rem 0rem 0.5rem 1rem" : "0.3rem 0rem"}       //top&bottom, left&right
                >
                    <FlexBetween onClick={handleClick}>
                        <img src={TranslateIcon} alt="profile" style={{ width: '25px' }} />

                        <FlexBetween style={{ width: '2rem', justifyContent: 'center' }}>
                            {isOpen ? <KeyboardArrowUpIcon style={{ color: '#707070' }} onClick={() => handleClick()} /> : <KeyboardArrowDownIcon style={{ color: '#707070' }} onClick={() => handleClose()} />}
                        </FlexBetween>
                    </FlexBetween>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Box padding='0 5px'>
                            <MenuItem className='menuitem-padding'>
                                <Button
                                    style={currentLocale === 'en' ? languageButtonStyle : { ...languageButtonStyle, backgroundColor: 'transparent', cursor: 'not-allowed', color: 'black' }}
                                    onClick={(e) => handleChange(e.currentTarget.value)}
                                    //onClick={(e) => handleChange(e.target.value)}
                                    disabled={currentLocale === 'en'}
                                    value="en"
                                >
                                    <img src={EnglishFlag} alt="eng" style={{ width: '1.8rem', paddingRight: '0.5rem' }} />
                                    En
                                </Button>
                            </MenuItem>

                            <MenuItem className='menuitem-padding'>
                                <Button
                                    style={currentLocale === 'zh' ? languageButtonStyle : { ...languageButtonStyle, backgroundColor: 'transparent', cursor: 'not-allowed', color: 'black' }}
                                    onClick={(e) => handleChange(e.currentTarget.value)}
                                    disabled={currentLocale === 'zh'}
                                    value="zh"
                                >
                                    <img src={ChineseFlag} alt="eng" style={{ width: '1.8rem', paddingRight: '0.5rem' }} />
                                    Zh
                                </Button>
                            </MenuItem>
                        </Box>
                    </Menu>
                </FlexBetween>
            </FlexBetween>
        )

    }


    return <AppBar
        sx={{
            position: "fixed",
            width: '100%',
            boxShadow: "none",
            backgroundColor: "#FFFFFF",
            paddingRight: isBelow600 ? '10px' : '40px'
        }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ margin: isBelow600 ? '0' : '10px 0 0 10px' }}>
                <img
                    src={GrabLogo}
                    alt="Logo"
                    className="logo"
                    width={isBelow600 ? '85px' : "125px"}
                    height={isBelow600 ? '40px' : "55px"}
                />
            </Box>

            {isBelow600 ? (
                <Grid container spacing={2} justifyContent='end' >
                    {/* translate */}
                    <Grid item className='navbar-content'>
                        <Dropdown />
                    </Grid>

                    {/* admin */}
                    <Grid item className='navbar-content'>
                        <img src={UserIcon} alt="report" width="25" height="25" />
                        <Typography style={{ color: '#707070', fontSize: '14px' }}>Admin</Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent='end' >
                    {/* date */}
                    <Grid item className='navbar-content'>
                        <img src={ClockIcon} alt="report" width="35" height="35" />
                        <Typography style={{ color: '#707070', fontSize: '14px' }}><DateTimeComponent /></Typography>
                    </Grid>

                    {/* translate */}
                    <Grid item className='navbar-content'>
                        <Dropdown />
                    </Grid>

                    {/* admin */}
                    <Grid item className='navbar-content'>
                        <img src={UserIcon} alt="report" width="25" height="25" />
                        <Typography style={{ color: '#707070', fontSize: '14px' }}>Admin</Typography>
                    </Grid>
                </Grid>)}


        </Toolbar>
    </AppBar>
}
