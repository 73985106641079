import axios from 'axios';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import OrangeButton from '../../../component/OrangeButton';
import CustomDataGrid from '../../../component/DataGrid';
import { useAuthContext } from '../../../hooks/useAuthContext';
// import { useFetchLogQuery, useFetchReportsQuery } from "../../../redux/slices/apiSlice";
// import { useSelector } from "react-redux";

const ReportData = () => {

    const dataGridWidth = useMediaQuery("(max-width: 1200px)")
    const { user } = useAuthContext()
    const Navigate = useNavigate()

    const reportColumns = [
        { field: 'rowIndex', headerName: 'No', width: 90, headerAlign: "center", align: "center" },
        {
            field: 'agent_id',
            headerName: <FormattedMessage id='agentID' />,
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'actual_pay',
            headerName: <FormattedMessage id='outstanding' />,
            type: 'number',
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'action',
            headerName: <FormattedMessage id="action" />,
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 300 : 350,
            renderCell: (params) => (
                <OrangeButton
                    id='view'
                    onClick={() => handleView(params.row.agent_id)}
                />
            ),
        },
    ]

    const handleView = (selectedId) => Navigate(`/log/${selectedId}`)

    const [pageState, setPageState] = useState({
        isLoading: false,
        reports: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchReport = async () => {
        try {
            const { data } = await axios.get(`/api/v1/report/all?page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })

            const reportList = data.foundReports.map((report, index) => {

                const agentId = report.credit_id ? report.credit_id.agent_id : null;

                return {
                    ...report,
                    agent_id: agentId,
                    rowIndex: index + 1,
                }
            })

            setPageState(old => ({ ...old, isLoading: false, reports: reportList, total: data.totalReports }))

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchReport()
    }, [pageState.page, pageState.pageSize])

    // const [fetchOptions, setFetchOptions] = useState({
    //     total: 0,
    //     page: 1,
    //     pageSize: 50
    // })

    // const [fetchData, setFetchData] = useState([])

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // let data = null
    // let reportList = null
    // const { data: fetch, isLoading } = useFetchReportsQuery({ page: fetchOptions.page, limit: fetchOptions.pageSize, token })

    // if (!isLoading) {
    //     data = fetch
    //     reportList = data.foundReports.map((report, index) => {

    //         const agentId = report.credit_id ? report.credit_id.agent_id : null;

    //         return {
    //             ...report,
    //             agent_id: agentId,
    //             rowIndex: index + 1
    //         }
    //     })
    // }

    // useEffect(() => {
    //     if (!isLoading) {
    //         setFetchData(reportList)
    //     }
    // }, [data])

    return (
        <CustomDataGrid
            columns={reportColumns}
            rows={pageState.reports}
            getRowId={(row) => row._id}
        />
    )
}

export default ReportData