import axios from 'axios';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CustomDataGrid from '../../../component/DataGrid';
import OrangeButton from '../../../component/OrangeButton';
import RedButton from '../../../component/RedButton';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import DeleteModal from '../../../component/DeleteModal';
// import { useSelector } from "react-redux";
// import { useFetchRequestsQuery, useDeleteLogMutation } from "../../../redux/slices/apiSlice";

const RequestData = () => {

    const Navigate = useNavigate()

    const { user } = useAuthContext()

    //const user = useSelector((state) => state?.user?.token)

    const dataGridWidth = useMediaQuery("(max-width: 1200px)")

    // request datagrid
    const requestColumns = [
        { field: 'rowIndex', headerName: 'No', width: 90, headerAlign: "center", align: "center" },
        {
            field: 'agent_id',
            headerName: <FormattedMessage id='agentID' />,
            minWidth: dataGridWidth ? 150 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'credit_limit',
            headerName: <FormattedMessage id='credit.request' />,
            minWidth: dataGridWidth ? 150 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'status',
            headerName: <FormattedMessage id='status' />,
            type: 'number',
            minWidth: dataGridWidth ? 150 : 230,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: <FormattedMessage id="createdAt" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mma"),
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'action',
            headerName: <FormattedMessage id="action" />,
            editable: false,
            headerAlign: "center",
            align: "center",
            minWidth: dataGridWidth ? 300 : 300,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <OrangeButton
                        id='process'
                        onClick={() => handleProcess(params.row._id)}
                    />

                    <RedButton
                        onClick={() => handleOpenDeleteModal(params.row._id)}
                        id='delete'
                    />
                </div>
            ),
        },
    ]

    const handleProcess = (selectedId) => Navigate(`/pending-details/${selectedId}`)


    const [pageState, setPageState] = useState({
        isLoading: false,
        request: [],
        total: 0,
        page: 1,
        pageSize: 50
    })


    // const [fetchOptions, setFetchOptions] = useState({
    //     total: 0,
    //     page: 1,
    //     pageSize: 50
    // })

    // const [fetchData, setFetchData] = useState([])

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // let data = null
    // let requestList = null;
    // const { data: fetch, isLoading } = useFetchRequestsQuery({ status: "pending", page: fetchOptions.page, limit: fetchOptions.pageSize, token })

    // if (!isLoading) {
    //     data = fetch
    //     requestList = data.foundRequests.map((request, index) => ({
    //         ...request,
    //         rowIndex: index + 1
    //     }))
    // }

    // useEffect(() => {
    //     if (!isLoading) {
    //         setFetchData(requestList)
    //     }
    // }, [data])

    const fetchRequest = async () => {
        try {
            const { data } = await axios.get(`/api/v1/credit/all?status=pending&page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })

            const requestList = data.foundRequests.map((request, index) => ({
                ...request,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, request: requestList, total: data.pageTotal }))

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchRequest()
    }, [pageState.page, pageState.pageSize])

    // delete
    const [selectedId, setSelectedId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const handleOpenDeleteModal = (id) => {
        setSelectedId(id)
        setOpenDeleteModal(true)
    }

    const handleCancel = () => {
        setOpenDeleteModal(false)
    }

    const handleDelete = async () => {
        // const { error, isSuccess } = useDeleteLogMutation(selectedId, token)

        // setOpenDeleteModal(false)
        //useFetchRequest()

        try {
            const { data } = await axios.delete(`/api/v1/credit/delete?credit_id=${selectedId}`,
                {
                    headers: { 'Authorization': `Bearer ${user.token}` },
                })

            setOpenDeleteModal(false)
            fetchRequest()

        } catch (error) {
            console.error(error)
        }

    }

    return (
        <>
            <CustomDataGrid
                columns={requestColumns}
                rows={pageState.request}
                getRowId={(row) => row._id}
            />

            {openDeleteModal && (
                <DeleteModal
                    message='delete.confirmation.message'
                    onDelete={handleDelete}
                    onCancel={handleCancel}
                    onClose={handleCancel}
                />
            )}
        </>
    )
}

export default RequestData