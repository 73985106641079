import { Box, Card, CardHeader, Collapse, Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import Header from '../../component/Header'
import { FormattedMessage } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../../css/dashboard.css'
// import { useDispatch, useSelector } from "react-redux";

export const Dashboard = ({ boxSize, defaultBox }) => {
    const [expand, setExpand] = useState()
    const [expandTotalUnits, setExpandTotalUnits] = useState()
    const [expandTop10, setExpandTop10] = useState()

    //const user = JSON.parse(localStorage.getItem('user'))
    //console.log(user)

    return (
        <Box sx={boxSize}>
            <Header title={<FormattedMessage id='dashboardNav' />} />

            <Box sx={defaultBox} boxShadow='1px 2px 5px #cccccc'>
                <Grid container spacing={1} columns={12} >
                    <Grid item xs={12} sm={6} md={6} xl={6} borderRight={{ xs: "none", sm: "1px solid #cdced1" }} borderBottom={{ xs: "1px solid #cdced1", sm: 'none' }}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='pending.request' /></Typography>
                            <Typography className='sum-data'>hh</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Box className='sum-box'>
                            <Typography className='sum-text'><FormattedMessage id='outstanding.amount' /></Typography>
                            <Typography className='sum-data'>jj</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ paddingTop: '20px' }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} paddingBottom='30px'>
                        <Box>
                            <Card className="collapsibleCard">
                                <CardHeader
                                    onClick={() => setExpandTop10(!expandTop10)}
                                    title={<Typography className="collapsibleCardTitle"><FormattedMessage id='top' /></Typography>}
                                    action={
                                        <IconButton>
                                            {expandTop10 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    }
                                />
                                <Collapse in={expandTop10}>
                                    <Box paddingLeft={5} paddingRight={5} paddingBottom={5}>
                                        {/* {dashData.totalTop.map((item, index) => ( */}
                                        <Box
                                            // key={index}
                                            display="flex" className="collapsibleCardInfoSection">
                                            <Typography className="collapsibleCardTop10">
                                                {/* <FormattedMessage id={`top${index + 1}`} /> */}
                                                123
                                            </Typography>
                                            <Divider className="divider_shorter" orientation="vertical" flexItem />
                                            <Box className="top10BoxInBox">
                                                <Typography className="collapsibleCardResult">name</Typography>
                                                <Divider className="divider_shorter_inBox" orientation="vertical" flexItem />
                                                <Typography className="collapsibleCardResult">amount</Typography>
                                            </Box>
                                        </Box>
                                        {/* ))} */}
                                    </Box>
                                </Collapse>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}
