import * as yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLogin } from "../../hooks/useLogin"
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from "@mui/material"
import GrabLogo from '../../assets/grab_logo.png'
import '../../css/loginPage.css'

// TESTING
// import { useDispatch, useSelector } from "react-redux";
// import { login } from "../../redux/slices/userSlice";
// import { useUserLoginQuery } from "../../redux/slices/apiSlice";
// import ErrorMsg from "../../component/ErrorMsg";
// import { skipToken } from "@reduxjs/toolkit/query";

const LoginPage = () => {

    // const navigate = useNavigate()

    // Dispatch
    // const dispatch = useDispatch()

    const { login } = useLogin()
    const [error, setError] = useState(null)

    // const [creds, setCreds] = useState(skipToken);

    // const { data, error } = useUserLoginQuery(creds)
    // Submit
    const handleFormSubmit = async (values) => {
        // setCreds(values)
        try {
            await login(values)
        } catch (error) {
            setError(error.message);
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (data) {
    //         dispatch(login(data))
    //         navigate("/")
    //     }
    // }, [data])

    // useEffect(() => {
    //     if (error) {
    //         console.log(error.data.message)
    //     }
    // }, [error])

    // Get Data from Store
    // const { loading } = useSelector((state) => state?.user)

    // Example
    // error: {statusCode: 400, error: 'Bad Request', message: 'User Not Found'} 
    // loading: false

    // const handleFormSubmit = async (values) => {

    //     dispatch(loginUserAction(values))

    //     try {
    //         // await login(values)
    //     } catch (error) {
    //         // setError(error.message);
    //         console.log(error)
    //     }
    // }

    const handleCloseErrorDialog = () => {
        // setError(null)
    }

    return (
        <Box className="container">
            <Box className="title-component" >
                <Typography className="title-text" >
                    <img src={GrabLogo} alt="Logo" className="logo" width="180px" height="85px" />
                </Typography>
            </Box>

            <Box className="login-form-component" >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValuesLogin}
                    validationSchema={loginSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Box className="login-field-component" >
                                <TextField
                                    label={<FormattedMessage id="login.username" />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    error={!!touched.username && !!errors.username}
                                    helperText={touched.username && errors.username}
                                    sx={{ gridColumn: "span 4" }}
                                />
                                <TextField
                                    label={<FormattedMessage id="login.password" />}
                                    type="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={Boolean(touched.password) && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 4" }}
                                />
                            </Box>

                            <Box className="login-button-component">
                                <Button
                                    // Disabled when loading = true
                                    // disabled={loading}
                                    type="submit"
                                    className="login-field-button"
                                >
                                    <FormattedMessage id="login" />
                                </Button>
                            </Box>

                            {/* TODO: Dialog Not Show */}
                            {/* <Dialog open={Boolean(error)}>
                                <DialogTitle>
                                <FormattedMessage id="login.failed.title" />
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                    <FormattedMessage id="login.failed.message" />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button
                                onClick={ }
                                >ok</Button>
                                </DialogActions>
                            </Dialog> */}
                        </form>
                    )}
                </Formik>

                {/* Put Outside Formik */}
                {/* {error && <ErrorMsg title="Error" message={error?.data?.message} />} */}




            </Box>
        </Box >
    )
}

const loginSchema = yup.object().shape({
    username: yup.string().required(<FormattedMessage id="login.username.error" />),
    password: yup.string().required(<FormattedMessage id="login.password.error" />),
})

const initialValuesLogin = {
    username: "",
    password: "",
}

export default LoginPage