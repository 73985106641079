import axios from 'axios';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import CustomDataGrid from '../../../component/DataGrid';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import { useAuthContext } from '../../../hooks/useAuthContext';
// import { useFetchRequestsQuery } from "../../../redux/slices/apiSlice";
// import { useSelector } from "react-redux";

const RejectedData = () => {

    const dataGridWidth = useMediaQuery("(max-width: 1200px)")
    const { user } = useAuthContext()

    // rejected datagrid
    const rejectedColumns = [
        { field: 'rowIndex', headerName: 'No', width: 90, headerAlign: "center", align: "center" },
        {
            field: 'agent_id',
            headerName: <FormattedMessage id='agentID' />,
            minWidth: dataGridWidth ? 180 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: 'credit_limit',
            headerName: <FormattedMessage id='amount' />,
            type: 'number',
            minWidth: dataGridWidth ? 180 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        // {
        //     field: 'createdAt',
        //     headerName: <FormattedMessage id="createdAt" />,
        //     valueFormatter: (params) =>
        //         moment(params.value).format("DD/MM/YYYY h:mma"),
        //     minWidth: dataGridWidth ? 250 : 280,
        //     headerAlign: "center",
        //     align: "center",
        //     editable: false,
        // },
        {
            field: 'updatedAt',
            headerName: <FormattedMessage id="rejectedAt" />,
            valueFormatter: (params) =>
                moment(params.value).format("DD/MM/YYYY h:mma"),
            minWidth: dataGridWidth ? 250 : 280,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
    ]

    const [pageState, setPageState] = useState({
        isLoading: false,
        rejected: [],
        total: 0,
        page: 1,
        pageSize: 50
    })

    const fetchRejected = async () => {
        try {
            const { data } = await axios.get(`/api/v1/credit/all?status=rejected&page=${pageState.page}&limit=${pageState.pageSize}`, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })

            const rejectedList = data.foundRequests.map((rejected, index) => ({
                ...rejected,
                rowIndex: index + 1
            }))

            setPageState(old => ({ ...old, isLoading: false, rejected: rejectedList, total: data.pageTotal }))

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchRejected()
    }, [pageState.page, pageState.pageSize])

    // const [fetchOptions, setFetchOptions] = useState({
    //     total: 0,
    //     page: 1,
    //     pageSize: 50
    // })

    // const [fetchData, setFetchData] = useState([])

    // Get Data from Store
    // const { token } = useSelector((state) => state?.user)

    // let data = null
    // let requestList = null
    // const { data: fetch, isLoading } = useFetchRequestsQuery({ status: "rejected", page: fetchOptions.page, limit: fetchOptions.pageSize, token })

    // if (!isLoading) {
    //     data = fetch
    //     requestList = data.foundRequests.map((request, index) => ({
    //         ...request,
    //         rowIndex: index + 1
    //     }))
    // }

    // useEffect(() => {
    //     if (!isLoading) {
    //         setFetchData(requestList)
    //     }
    // }, [data])

    return (
        <CustomDataGrid
            columns={rejectedColumns}
            rows={pageState.rejected}
            getRowId={(row) => row._id}
        />
    );
};

export default RejectedData